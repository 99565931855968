import classes from "./SwitchBtn.module.css"
// import {useState, useEffect} from "react"

function SwitchBtn({children, isActive, onClick}){
    let buttonClass = classes.btnOff;
    if(isActive){
        buttonClass = classes.btnOn;
    }
    return(
        <button 
        className={buttonClass} 
        onClick={onClick}>{children}</button>
    )
}

export default SwitchBtn;