export const SET_PROJECT_NAME = "SET_PROJECT_NAME"

//Set trace property by device titles
export const SET_ANTENNA_LOW = "SET_ANTENNA_LOW"
export const SET_ANTENNA_HIGHT = "SET_ANTENNA_HIGHT"
export const SET_BLOCK = "SET_BLOCK"
export const SET_CLIMATIC_ZONE = "SET_CLIMATIC_ZONE"

//Set trace property manual
export const SET_HIGHT_DBM = "SET_HIGHT_DBM"
export const SET_LOW_DBM = "SET_LOW_DBM"
export const SET_POWER_OUT = "SET_POWER_OUT"
export const SET_FREQ = "SET_FREQ"
export const SET_PRX = "SET_PRX"

export const SET_RANGE = "SET_RANGE"

export const SET_LOW_ADRESS = "SET_LOW_ADRESS"
export const SET_HIGHT_ADRESS = "SET_HIGHT_ADRESS"

export const SET_LOW_LAT = "SET_LOW_ALPHA_COORD"
export const SET_LOW_LON = "SET_LOW_LAMBDA_COORD"
export const SET_HIGH_LAT = "SET_HIGHT_ALPHA_COORD"
export const SET_HIGH_LON = "SET_HIGHT_LAMBDA_COORD"


