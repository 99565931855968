import classes from "./AnsDataForm.module.css"
export default function AnsDataForm({ansData, isCalced})
{
    // console.log(ansData)
    if(isCalced){
        return(
            <>
                <table className={classes.monoTable}>
                    <tr>
                        <td className={classes.cellHat}>Доступность</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.cellData}>{row.probability} %</td>)
                        })}
                    </tr>
                    <tr>
                        <td className={classes.cellHat}>Полное затухание (дБ)</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.cellData}>{row.att.toFixed(1)}</td>)
                        })}
                    </tr>
                    <tr>
                        <td className={classes.cellHat}>Радио бюджет (дБ)</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.cellData}>{row.budget.toFixed(0)}</td>)
                        })}
                    </tr>
                    <tr>
                        <td className={classes.cellHat}>Модуляция</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.cellData}>{row.modulation}</td>)
                        })}
                    </tr>
                    <tr>
                        <td className={classes.cellHat}>Скорость передачи (мбит/c)</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.cellData}>{row.speed}</td>)
                        })}
                    </tr>
                    <tr>
                        <td className={classes.cellHat}>Чувствительность приёмника (дБм)</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.cellData}>{row.prx}</td>)
                        })}
                    </tr>
                    <tr>
                        <td className={classes.cellHat}>Выходная мощность (дБм)</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.cellData}>{row.pout}</td>)
                        })}
                    </tr>
                    <tr>
                        <td className={classes.cellHat}>RSSI (дБм)</td>
                        {ansData.attTable.map((row) => {return(
                            <td className={classes.rssiData}>{row.RSSI.toFixed(1)}</td>)
                        })}
                    </tr>
                </table>
            </>
        )
    }
}