import classes from "./modal.css"

function FailedCalcsModal({active, setActive}){
    return (
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className={"modal__content"} onClick = {e => e.stopPropagation()}>
            Коэффициент доступности меньше 95%, расчёт невозможен/недоступен.
            </div>
        </div>
    )
}

export default FailedCalcsModal