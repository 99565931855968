import classes from "./OutProps.module.css"

function OutProps(){
    return(
        <div className={classes.wrapper}>
            <span className={classes.radio}>
                <input type="radio" name="" id="" disabled/>
                <label htmlFor="">Детальный расчёт</label>
            </span>
            <div className={classes.dataForm}>

                <div className={classes.line}>
                    <span className={classes.title}>BNL</span>
                    <span className={classes.input}>
                        <input type="text" disabled/>
                        <label htmlFor="">dBm/Hz</label>
                    </span>
                </div>

                <div className={classes.line}>
                    <span className={classes.title}>NF</span>
                    <span className={classes.input}>
                        <input type="text" disabled/>
                        <label htmlFor="">dB</label>
                    </span>
                </div>

                <div className={classes.line}>
                    <span className={classes.title}>BW</span>
                    <span className={classes.input}>
                        <input type="text" disabled/>
                        <label htmlFor="">Мгц</label>
                    </span>
                </div>

                <div className={classes.line}>
                    <span className={classes.title}>SNR</span>
                    <span className={classes.input}>
                        <input type="text" disabled/>
                        <label htmlFor="">dBc</label>
                    </span>
                </div>

            </div>
        </div>
    )
}

export default OutProps;