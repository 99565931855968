import {
    SET_PROJECT_NAME,
    SET_ANTENNA_LOW,
    SET_ANTENNA_HIGHT,
    SET_BLOCK,
    SET_CLIMATIC_ZONE,
    SET_HIGHT_DBM,
    SET_LOW_DBM,
    SET_POWER_OUT,
    SET_FREQ,
    SET_RANGE,
    SET_LOW_ADRESS,
    SET_HIGHT_ADRESS,
    SET_PRX,
    SET_LOW_LAT,
    SET_LOW_LON,
    SET_HIGH_LAT,
    SET_HIGH_LON
} from '../constants'

import deviceLibrary from "../../json/deviceLibrary.json"
import climaticZones from "../../json/climaticZones.json"

import getDistanceFromLatLonInMeters from '../../lib/getDistanceFromLatLonInMeters'

const initBlock = deviceLibrary.radioBlocks["mayak1000e"]
// console.log(initBlock)
const initAntennaHigh = deviceLibrary.antennas["lens20"]
// console.log(deviceLibrary)
const initAntennaLow = deviceLibrary.antennas["lens20"]

const initialState = {
  projectName: "Unnamed project",
  block: initBlock,
  antennaHight: initAntennaHigh,
  antennaLow: initAntennaLow,
  climaticzone: "B",

  freq: initBlock.freq,
  pout: initBlock.pout,

  g1: initAntennaHigh.gain,
  g2: initAntennaLow.gain,

  coords:{
    high:{
        lat: 59.92183,
        lon: 30.38329
    },
    low:{
        lat: 59.92439,
        lon: 30.37329
    }
  },

  range: getDistanceFromLatLonInMeters(59.92183, 30.38329, 59.92439, 30.37329),

  prx: 0
}

const traceProperties = (state = initialState, {type, payload}) => {
    switch(type){
        case SET_PROJECT_NAME:
            return {
                ...state,
                projectName: payload
            }

        case SET_BLOCK:
            let new_block = deviceLibrary.radioBlocks[payload]
            return {
                ...state,
                block: new_block,
                antennaHight: deviceLibrary.antennas["lens20"],
                antennaLow: deviceLibrary.antennas["lens20"],
                freq: new_block.freq,
                pout: new_block.pout

            }
        case SET_ANTENNA_LOW:
            {
                let antenna = deviceLibrary.antennas[payload]
                return {
                    ...state,
                    antennaLow:antenna,
                    g2:antenna.gain
                }
            }
        case SET_ANTENNA_HIGHT:
            {
                // console.log(payload)
                let antenna = deviceLibrary.antennas[payload]
                return {
                    ...state,
                    antennaHight:antenna,
                    g1:antenna.gain
                }
            }
        case SET_CLIMATIC_ZONE:
            {
                return {
                    ...state,
                    climaticzone: payload
                }
            }
        case SET_HIGHT_DBM:
            {
                return {
                    ...state,
                    g1:payload
                }
            }
        case SET_LOW_DBM:
            {
                return {
                    ...state,
                    g2:payload
                }
            }
        case SET_POWER_OUT:
            {
                return {
                    ...state,
                    pout: payload
                }
            }
        case SET_FREQ:
            {
                return {
                    ...state,
                    freq: payload
                }
            }
        case SET_RANGE:
            {
                return {
                    ...state,
                    range: payload
                }
            }
        case SET_PRX:
            {
                return {
                    ...state,
                    prx: payload
                }
            }
        case SET_LOW_LAT:{
            return {
                ...state,
                coords: {
                    ...state.coords,
                    low: {
                        ...state.coords.low,
                        lat: payload
                    }
                }
            }
        }
        case SET_LOW_LON:{
            return {
                ...state,
                coords: {
                    ...state.coords,
                    low: {
                        ...state.coords.low,
                        lon: payload
                    }
                }
            }
        }
        case SET_HIGH_LAT:{
            return {
                ...state,
                coords: {
                    ...state.coords,
                    high: {
                        ...state.coords.high,
                        lat: payload
                    }
                }
            }
        }
        case SET_HIGH_LON:{
            return {
                ...state,
                coords: {
                    ...state.coords,
                    high: {
                        ...state.coords.high,
                        lon: payload
                    }
                }
            }
        }
        default: return state
    }
}
export default traceProperties;