import classes from "./StationAdresses.module.css"

function StationAdresses(){
    return(
        <div className={classes.wrapper}>
            <span className={classes.title}>Адрес станций</span>
            <div className={classes.dataForm}>
                <span>
                    <label htmlFor="">H</label>
                    <input type="text"/>
                </span>
                <span>
                    <label htmlFor="">L</label>
                    <input type="text" />
                </span>
                
            </div>
        </div>
    )
}

export default StationAdresses;