import classes from "./DropDownElement.module.css"
import {FaChevronDown} from "react-icons/fa"

export default function DropDownElement({title, property, setProperty, propertyList}){

    return(
        <div className={classes.wrapper}>
            <div className={classes.title}>{title}</div>
            <div className={classes.dropdown}>
                <div className={classes.componentTitle}>{property}</div>
                <button className={classes.dropbtn}>
                    <FaChevronDown></FaChevronDown>
                </button>
                <div className={classes.dropdownContent}>
                    {
                        propertyList.map((el) => {
                            if(el.disabled != true){
                                return(
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault()
                                        setProperty(el.key)
                                        // document.getElementsByClassName('dropdownContent').style.display = 'none'
                                    }}
                                        key = {el.key}
                                    >{el.title}</a>
                                )
                            }
                            
                        })
                    }
                </div>
            </div>
        </div>
    )
}