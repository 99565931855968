import deviceLibrary from "../json/deviceLibrary.json"
import climaticZones from "../json/climaticZones.json"

function calcTable({g1, g2, range, freq, device, climaticzone}){
    const modulations = deviceLibrary.radioBlocks[device].modulations
    // console.log(modulations)
    // const climaticzones = climaticZonesMap.map.get(climaticzone);
    const climaticZone = climaticZones[climaticzone]

    range = range/1000;

    let Lfsl = 92.4 + 20 * Math.log10(range) + 20 * Math.log10(freq);
    let oxAtt = 0.4 * range;


    let attTable = []

    let modulationIndex = 0
    let buffer = undefined;

    let toAdd = true;

for (const pot in climaticZone){
        let budget = 0;
        let attitude = 1;
        let RSSI = Number(g1) + Number(g2) + Number(modulations[modulationIndex].pout) - Number(Lfsl) - Number(oxAtt);
        while(true)
        {
            if(modulationIndex <= modulations.length - 1){
                budget = Number(g1) + Number(g2) + Number(modulations[modulationIndex].pout) - Number(modulations[modulationIndex].sensReceive)
                attitude = Lfsl + oxAtt + climaticZone[pot];
                // console.log(`\nМодуляция: ${modulations[modulationIndex].title}\nБюджет:${budget}\nЗатухание:${attitude}`)
                if(budget > attitude){
                    toAdd = true;
                    buffer = {
                        probability: pot,
                        att: attitude,
                        budget: budget,
                        oxAtt: oxAtt,
                        Lfsl: Lfsl,
                        RSSI: RSSI,
                        modulation: modulations[modulationIndex].title,
                        speed: modulations[modulationIndex].speed,
                        prx: modulations[modulationIndex].sensReceive,
                        pout: modulations[modulationIndex].pout
                    }
                    break;
                } else {
                    modulationIndex++;
                    // console.log("iteration")
                    if(toAdd && buffer != undefined){
                        // console.log(buffer)
                        attTable.push(buffer)
                    }
                    toAdd = false;
                    continue;
                }
            } else {
                modulationIndex = modulationIndex - 1
                break
            }
        }
    }

    if(toAdd)
    {
        attTable.push(buffer)
    }

    return attTable;
}

export default calcTable;