import { useEffect, useState } from "react";
// import axios from "axios";
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux'

/* Components */
import Header from "./components/Header/Header";
import SwitchPanel from "./components/SwitchPanel/SwitchPanel";
import PropertiesForm from "./components/Forms/PropertiesForm/PropertiesForm";
import ZonePropertiesForm from "./components/Forms/ZonePropertiesForm/ZonePropertiesForm";
import StationCoordsForm from "./components/Forms/StationCoordsForm/StationCoordsForm";
import MapForm from "./components/Forms/MapForm/MapForm";
import AnsDataForm from "./components/Forms/AnsDataForm/AnsDataForm";
import SubmitBtn from "./components/UI/Buttons/SubmitBtn/SubmitBtn";
import FailedCalcsModal from "./components/Messages/FailedCalcsModal";
/**************/

/* JSON */
import deviceLibrary from "./json/deviceLibrary.json"

/* Parser*/
import {getAntennaProps, getProductProps, getPrx} from "./lib/TraceComponentsCollection"

/* CSS */
import "./App.css"

/*Calculation function*/
import calcTable from "./lib/calcTable";

import createPdf from "./lib/createPDF";
import { flushSync } from "react-dom";

// axios.defaults.baseURL = 'http://localhost:3001/';
//axios.defaults.baseURL = 'http://94.103.93.118:3001/'
// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const Panels = {
    calcParams: 1,
    calculcator: 0    
}

function App() {

    const traceProperties = useSelector(store => store.traceProperties)

    const[isCalced, setCalced] = useState(false)
    /** Активная панель параметров:
     * 0 - расчёт параметров(calcParams)
     * 1 - калькулятор(calculator)
     */
    const[activePanel, setActivePanel] = useState(Panels.calcParams);
  
    const [ansData, setAnsData] = useState({
        isCalced: false,
        budget: 0,
        lfsl: 0,
        attTable: []
    })

    //Прослойка, рефакторить
    const getTraceDataToPDF = (ansData) => {
        return {
            traceName:  traceProperties.projectName,
            blockType:  traceProperties.block.title,
            antennaV:   traceProperties.antennaHight.title,
            antennaL:   traceProperties.antennaLow.title,
            range: traceProperties.range,
            coords:{
                blockH: [traceProperties.coords.high.lat,
                         traceProperties.coords.hight.lon],
                blockL: [traceProperties.coords.low.lat,
                         traceProperties.coords.low.lon],
            },
            climaticzone: traceProperties.climaticzone,
            ansData: ansData

        }
    }

    const [modalActive, setModalActive] = useState(false)

    return(
        <div className="wrapper">
            <div className="innerWrapper">
                <Header/>
                <div className="switch-buttons">
                <SwitchPanel        activePanel={activePanel}
                                        setActivePanel={setActivePanel}
                                        className="switch-buttons"/></div>
                <div className="main-container">
                    <div style={{
                        "margin-top" : "13px"
                    }}>
                        <PropertiesForm activePanel={activePanel}/>
                    </div>

                    <div style={{
                        "margin-top" : "13px",
                        "margin-right" : "13px"
                    }}>
                        <MapForm/>
                    </div>
                    
                    <div style={{
                        "margin-top" : "13px",
                        "margin-right" : "13px",
                        "margin-left"   :"13px"
                    }}>
                        <ZonePropertiesForm />
                    </div>

                    <div style={{
                        "margin-right" : "13px"
                    }}>
                        <StationCoordsForm  />
                    </div>
                    
                    <div className="submit-buttons-container">
                    <SubmitBtn onClick={() => {
                        
                        let calcData = calcTable({...traceProperties, device: traceProperties.block.key})
                        // console.log(calcData)
                        if (calcData.length != 0)
                        {
                            setCalced(true)
                            setAnsData({attTable: calcData, isCalced:true})
                        } else {
                            setCalced(false)
                            console.log("NO CALCS")
                            setModalActive(true)
                        }
                        
                    }}>Рассчитать</SubmitBtn>

                    <SubmitBtn onClick={() => {
                        if(isCalced){
                            createPdf(ansData.attTable, traceProperties)
                        }
                        
                    }}>Отчёт PDF</SubmitBtn>
                    </div>
                </div>
            </div>
        <AnsDataForm ansData={ansData} isCalced={isCalced}></AnsDataForm>
        <FailedCalcsModal active={modalActive} setActive={setModalActive}/>
        </div>
    )

}

export default App;