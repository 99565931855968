import React from "react";
import styles from "./PhysicalProps.module.css"

import {useSelector, useDispatch} from 'react-redux'

import {
    setHightDbm,
    setLowDbm,
    setPOut,
    setRange
} from '../../../../redux/actions/actionCreator'

function PhysicalProps({props, getProps}){
    const traceParams = useSelector(store => store.traceProperties)
    const dispatch = useDispatch()

    const setHightDbmClb = (e) => {dispatch(setHightDbm(e.target.value))}
    const setLowDbmClb = (e) => {dispatch(setLowDbm(e.target.value))}
    const setPOutClb = (e) => {dispatch(setPOut(e.target.value))}
    const setRangeClb = (e) => {dispatch(setRange(e.target.value))}

    return(
        <div className={styles.outerWrapper}>
            <div className={styles.innerWrapper}>
                <span>
                    <label htmlFor="gainH">Коэффициент усиления антенны "H" (dBi)</label>
                    <input type="number" name="gainH" onChange={setHightDbmClb} value={traceParams.g1}/>
                </span>
                <span>
                    <label htmlFor="gainL">Коэффициент усиления антенны "L" (dBi)</label>
                    <input type="number" name="gainL" onChange={setLowDbmClb} value={traceParams.g2}/>
                </span>
                <span>
                    <label htmlFor="Pout">Выходная мощность передатчика (dBm)</label>
                    <input type="number" name="Pout" onChange={setPOutClb} value={traceParams.pout}/>
                </span>
                <span>
                    <label htmlFor="range">Расстояние (m)</label>
                    <input type="number" name="range" onChange={setRangeClb} value={traceParams.range}/>
                </span>
            </div>
        </div>
    )
}

export default PhysicalProps;