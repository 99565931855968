import StationAdresses from "../../UI/Properties/StationAdresses/StationAdresses"
import DropDownElement from "../../UI/new/DropDownElement/DropDownElement"

import { useSelector, useDispatch } from "react-redux"

import classes from "./ZonePropertiesForm.module.css"

import climaticZones from '../../../json/climaticZones.json'

import {setClimaticZone} from '../../../redux/actions/actionCreator'

function ZonePropertiesForm()
{
    // const cz_list = climaticZones.climaticzones.map(el => ({
        // key: el, 
        // title: el}))
    const cz_list = []
    for(const cz in climaticZones){
        cz_list.push({
            key: cz,
            title: cz
        })
    }
    const traceCZ = useSelector(store => store.traceProperties.climaticzone)
    const dispatch = useDispatch()
    const setClimaticsZoneClb = (cz) => {dispatch(setClimaticZone(cz))}
    return(
        <div className={classes.wrapper}>
            <DropDownElement
                title={"Дождевая зона"}
                property={traceCZ}
                setProperty={setClimaticsZoneClb}
                propertyList={cz_list}
                />
            <StationAdresses></StationAdresses>
        </div>
    )
}

export default ZonePropertiesForm