import {
    SET_PROJECT_NAME,
    SET_ANTENNA_LOW,
    SET_ANTENNA_HIGHT,
    SET_BLOCK,
    SET_CLIMATIC_ZONE,
    SET_HIGHT_DBM,
    SET_LOW_DBM,
    SET_POWER_OUT,
    SET_FREQ,
    SET_RANGE,
    SET_LOW_ADRESS,
    SET_HIGHT_ADRESS,
    SET_PRX,
    SET_LOW_LAT,
    SET_LOW_LON,
    SET_HIGH_LAT,
    SET_HIGH_LON,
} from '../constants'

export const setProjectName = (payload) => ({
    type: SET_PROJECT_NAME,
    payload
})

export const setAntennaLow = (payload) => ({
    type: SET_ANTENNA_LOW,
    payload
})

export const setAntennaHight = (payload) => ({
    type: SET_ANTENNA_HIGHT,
    payload
})

export const setBlock = (payload) => ({
    type: SET_BLOCK,
    payload
})

export const setClimaticZone = (payload) => ({
    type: SET_CLIMATIC_ZONE,
    payload
})

export const setHightDbm = (payload) => ({
    type: SET_HIGHT_DBM,
    payload
})

export const setLowDbm = (payload) => ({
    type: SET_LOW_DBM,
    payload
})

export const setPOut = (payload) => ({
    type: SET_POWER_OUT,
    payload
})

export const setFreq = (payload) => ({
    type: SET_FREQ,
    payload
})

export const setRange = (payload) => ({
    type: SET_RANGE,
    payload
})

export const setLowAdress = (payload) => ({
    type: SET_LOW_ADRESS,
    payload
})

export const setHightAdress = (payload) => ({
    type: SET_HIGHT_ADRESS,
    payload
})

export const setPRX = (payload) => ({
    type: SET_PRX,
    payload
})

export const setLowLat = (payload) => ({
    type: SET_LOW_LAT,
    payload
})

export const setLowLon = (payload) => ({
    type: SET_LOW_LON,
    payload
})

export const setHighLat = (payload) => ({
    type: SET_HIGH_LAT,
    payload
})

export const setHighLon = (payload) => ({
    type: SET_HIGH_LON,
    payload
})

