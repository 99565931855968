import React from "react";
import styles from "./Header.module.css"
import logo from "./HTP-Logo_rus_reverse.png"

function Header(){
    return(
        <header className={styles.wrapper}>
            <img className={styles.logo} src={logo} alt="logo"/>
            <span className={styles.title}>Калькулятор РРЛ</span>
        </header>
        
    )
}

export default Header;