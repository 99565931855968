import OutProps from "../OutProps/OutProps"
import classes from "./TraceProperties.module.css"

import {useSelector, useDispatch} from 'react-redux'

import {
    setHightDbm,
    setLowDbm,
    setPOut,
    setRange,
    setFreq
} from '../../../../redux/actions/actionCreator'


function TraceProperties(){
    const traceProperties = useSelector(store => store.traceProperties)
    const dispatch = useDispatch()

    const setHightDbmClb = (e) => {dispatch(setHightDbm(e.target.value))}
    const setLowDbmClb = (e) => {dispatch(setLowDbm(e.target.value))}
    const setPOutClb = (e) => {dispatch(setPOut(e.target.value))}
    const setRangeClb = (e) => {dispatch(setRange(e.target.value))}
    const setFreqClb = (e) => {dispatch(setFreq(e.target.value))}

    // console.log(traceProps)
    return(
        <div className={classes.wrapper}>
            <div className={classes.line}>
                <span className={classes.title}>G1</span>
                <span className={classes.input}>
                    <input type="number" onChange={setHightDbmClb} value={traceProperties.g1}/>
                    <label htmlFor="">dBi</label>
                </span>
            </div>

            <div className={classes.line}>
                <span className={classes.title}>G2</span>
                <span className={classes.input}>
                    <input type="number" onChange={setLowDbmClb} value={traceProperties.g2}/>
                    <label htmlFor="">dBi</label>
                </span>
            </div>

            <div className={classes.line}>
                <span className={classes.title}>Pout</span>
                <span className={classes.input}>
                    <input type="number" onChange={setPOutClb} value={traceProperties.pout}/>
                    <label htmlFor="">dBm</label>
                </span>
            </div>

            <div className={classes.line}>
                <span className={classes.title}>Расстояние</span>
                <span className={classes.input}>
                    <input type="number" onChange={setRangeClb} value={Number(traceProperties.range).toFixed(0)}/>
                    <label htmlFor="">М</label>
                </span>
            </div>

            <div className={classes.line}>
                <span className={classes.title}>Частота</span>
                <span className={classes.input}>
                    <input type="number" onChange={setFreqClb} value={traceProperties.freq}/>
                    <label htmlFor="">Ггц</label>
                </span>
            </div>
        </div>
    )
}

export default TraceProperties