
import PhysicalProps from "../../UI/Properties/PhysicalProps/PhysicalProps"
import TraceProperties from "../../UI/Properties/TraceProperties/TraceProperties"
import PRXinput from "../../UI/Properties/PRXinput/PRXinput"
import DropDownElement from "../../UI/new/DropDownElement/DropDownElement"
import SingleTextElement from "../../UI/new/SingleTextElement/SingleTextElement"


// import {useState} from "react"

import { useSelector, useDispatch } from "react-redux"

import classes from "./PropertiesForm.module.css"

import deviceLibrary from "../../../json/deviceLibrary.json"

import {
    setProjectName,
    setBlock,
    setAntennaLow,
    setAntennaHight,
} from '../../../redux/actions/actionCreator'



/*activePanel
    0 - расчёт параметров
    1 - калькулятор
*/

function PropertiesForm({activePanel}){
    const traceProperties = useSelector(store => store.traceProperties)
    // console.log(traceProperties)
    const dispatch = useDispatch()

    // const [products] = useState(getProductsTitles(deviceCollection))
    // const [antennas] = useState(getAntennasTitles(deviceCollection))

    const products = []
    const antennas = []

    for(let key in deviceLibrary.radioBlocks){
        // console.log(key)
        products.push({
            key: deviceLibrary.radioBlocks[key].key,
            title: deviceLibrary.radioBlocks[key].title
        })
    }

    for(let key in traceProperties.block.antennas){
        // console.log(key)
        antennas.push({
            key: traceProperties.block.antennas[key],
            title: deviceLibrary.antennas[traceProperties.block.antennas[key]].title
        })
    }

    const setProjectNameClb = (projName) => {dispatch(setProjectName(projName))}
    const setBlockClb = (blockType) => {dispatch(setBlock(blockType))}
    const setAntennaHightClb = (antennatype) => {dispatch(setAntennaHight(antennatype))}
    const setAntennaLowClb = (antennatype) => {dispatch(setAntennaLow(antennatype))}



    if(activePanel){
        return(
            <div className={classes.paramsCalc}>
                <SingleTextElement
                    title={'Пролёт'}
                    property = {traceProperties.projectName}
                    setProperty = {setProjectNameClb}/>
                <DropDownElement
                    title={'Тип блока'}
                    property={traceProperties.block.title}
                    setProperty={setBlockClb}
                    propertyList={products}/> 
                <DropDownElement
                    title={'Антенна H'}
                    property={traceProperties.antennaHight.title}
                    setProperty={setAntennaHightClb}
                    propertyList={antennas}/>
                <DropDownElement
                    title={'Антенна L'}
                    property={traceProperties.antennaLow.title}
                    setProperty={setAntennaLowClb}
                    propertyList={antennas}/>    
                <PhysicalProps/>
            </div>
        )
    } else {
        return(
            <div className={classes.calculator}>
                <TraceProperties/>
                <PRXinput/>
            </div>
            
            
        )
    }

}

export default PropertiesForm;