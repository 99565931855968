import classes from "./PRXinput.module.css"
import {useSelector, useDispatch} from 'react-redux'
import {setPRX} from '../../../../redux/actions/actionCreator'

function PRXinput({prx, getPrx}) {
    const traceProperties = useSelector(store => store.traceProperties.prx)
    const dispatch = useDispatch()

    const setPrxClb = (e) => {dispatch(setPRX(e.target.value))}
    return(
        <div className={classes.wrapper}>
            <span className={classes.radio}>
                <input type="radio" checked disabled/>
                Расчёт PRX
            </span>

            <span className={classes.line}>
                PRX
                <span className={classes.input}>
                    <input type="number" onChange={setPrxClb} value={traceProperties.prx}/>
                    dBm
                </span>
            </span>
        </div>
    )
}

export default PRXinput;