import classes from './SingleTextElement.module.css'

export default function SingleTextElement({title, property, setProperty})
{
    return(
        <div className={classes.wrapper}>
            <div className={classes.title}>{title}</div>
            <input className={classes.input} type="text" onChange={e => setProperty(e.target.value) } value={property}/>         
        </div>
    )
}