import {React, useState, useEffect} from "react";
import {YMaps, Map, SearchControl, Placemark} from "@pbe/react-yandex-maps"
import classes from "./MapForm.module.css"

import { useSelector, useDispatch } from "react-redux";

import {
    setLowLat,
    setLowLon,
    setHighLat,
    setHighLon,
    setRange
} from '../../../redux/actions/actionCreator'

import getDistanceFromLatLonInMeters from "../../../lib/getDistanceFromLatLonInMeters"


// Компонент интерактивной карты. Кликом добавляются две точки. После второй вызывается принятый Callback

function getColor(type){
    switch(type){
        case "H":
            return("red");
        case "L":
            return("blue")
    }
}

const MapForm = ({width = 450, height=220}) => {

    const coords = useSelector(store => store.traceProperties.coords)
    const dispatch = useDispatch()

    const setRangeClb = (value) => {dispatch(setRange(value))}
    const setLowLatClb = (value) => {dispatch(setLowLat(value))}
    const setLowLonClb = (value) => {dispatch(setLowLon(value))}
    const setHighLatClb = (value) => {dispatch(setHighLat(value))}
    const setHighLonClb = (value) => {dispatch(setHighLon(value))}
    

    const [pointsBuf, setPointsBuf] = useState([
        {
            title: "H",
            phi:    coords.high.lat,
            lambda: coords.high.lon,
        },
        {
            title: "L",
            phi:    coords.low.lat,
            lambda: coords.low.lon
        }
    ]);

    const [pointsCounter, setPointsCounter] = useState(2);

    
    const addPoint = (e) => {
        if(pointsCounter === 0) {
            setPointsBuf([...pointsBuf, {
                iconColor: 'red',
                title: "H",
                phi:    Number(e.get('coords')[0]),
                lambda: Number(e.get('coords')[1])
            }])
            setPointsCounter(pointsCounter + 1);
        } else if(pointsCounter === 1) {
            setPointsBuf([...pointsBuf, {
                iconColor: 'blue',
                title: "L",
                phi:    Number(e.get('coords')[0]),
                lambda: Number(e.get('coords')[1])
            }])
            setPointsCounter(pointsCounter + 1);
        }
    }

    const resetPointsBuf = () => {
        setPointsBuf([]);
        setPointsCounter(0);
    }

    return(
        // I dont understand how this handler working...
        <div className={classes.wrapper}>
            <YMaps>
                <Map
                defaultState={{
                    center: [   59.92217406683522,
                                30.40560668945311],
                    zoom: 12}}
                onClick={addPoint}
                width={width}
                height={height}
                z-index={-1}
                // style={{borderRadius: 15}}>
>
                    <SearchControl/>
                    {pointsBuf.map(point => {
                        return(
                            <Placemark  geometry={[point.phi, point.lambda]}
                                        options={{
                                            iconColor: getColor(point.title)
                                        }}
                                        properties={{
                                            iconContent: point.title
                                        }}/>
                        )
                    })}
                </Map>
            </YMaps>
            <button className={classes.refreshButton}onClick={resetPointsBuf}>Очистить метки</button>
            <button className={classes.refreshButton}onClick={() => {
                if(pointsCounter === 2)
                {
                    setLowLatClb(Number(pointsBuf[0].phi).toFixed(5))
                    setLowLonClb(Number(pointsBuf[0].lambda).toFixed(5))
                    setHighLatClb(Number(pointsBuf[1].phi).toFixed(5))
                    setHighLonClb(Number(pointsBuf[1].lambda).toFixed(5))
                    setRangeClb(getDistanceFromLatLonInMeters(Number(pointsBuf[0].phi), Number(pointsBuf[0].lambda), Number(pointsBuf[1].phi), Number(pointsBuf[1].lambda)))
                }
            }}>Отправить на форму</button>
        </div>
    )
}

export default MapForm;