import classes from "./StationCoordsForm.module.css"
import latitudePic from "../../../img/latitude.svg"
import longitudePic from "../../../img/longitude.svg"
import heightPic from "../../../img/height.svg"

import {useSelector, useDispatch} from 'react-redux'

import {
    setLowLat,
    setLowLon,
    setHighLat,
    setHighLon
} from '../../../redux/actions/actionCreator'

function StationCoordsForm(){
    const coords = useSelector(store => store.traceProperties.coords)
    const dispatch = useDispatch()

    const setLowLatClb = (e) => {dispatch(setLowLat(e.target.value))}
    const setLowLonClb = (e) => {dispatch(setLowLon(e.target.value))}
    const setHighLatClb = (e) => {dispatch(setHighLat(e.target.value))}
    const setHighLonClb = (e) => {dispatch(setHighLon(e.target.value))}

    return(
        <div className={classes.wrapper}>
            <span className={classes.title}>Координаты станций</span>
            <div className={classes.dataForm}>
                <span>
                    <label htmlFor="">Блок L</label>
                    <img src={longitudePic} alt="lat" srcset="" />
                    
                    <input className={classes.coordInput}   step = "0.01" type="number" placeholder="долгота" 
                            onChange={setLowLatClb}
                            value={coords.low.lat}   />
                    <img src={latitudePic} alt="lat" srcset="" />
                    <input className={classes.coordInput}   step = "0.01" type="number" placeholder="широта"  
                            onChange={setLowLonClb}
                            value={coords.low.lon}  />
                    {/* <img src={heightPic} alt="lat" srcset="" /> */}
                    {/* <input className={classes.heightInput}  step = "0.01" type="number" placeholder="высота"  onChange={(e) => setStationsCoords({...stationsCoords, height1: Number(e.target.value)})}     value={Number(stationsCoords.height1).toFixed(5)}  /> */}
                </span>
                <span>
                    <label htmlFor="">Блок H</label>
                    <img src={longitudePic} alt="lat" srcset="" />
                    <input className={classes.coordInput} step = "0.01" type="number" placeholder="долгота" 
                            onChange={setHighLatClb}
                            value={coords.high.lat}   />
                    <img src={latitudePic} alt="lat" srcset="" />
                    <input className={classes.coordInput} step = "0.01" type="number" placeholder="широта" 
                            onChange={setHighLonClb}
                            value={coords.high.lon}  />
                    {/* <img src={heightPic} alt="lat" srcset="" /> */}
                    {/* <input className={classes.heightInput}  step = "0.01" type="number" placeholder="высота"  onChange={(e) => setStationsCoords({...stationsCoords, height2: e.target.value})}     value={Number(stationsCoords.height2).toFixed(5)}  /> */}
                </span>
            </div>
        </div>
    )
}

export default StationCoordsForm;