import jsPDF from "jspdf";
import font from "./font";

function createPDF(attTable, traceProperties){
    var callAddFont = function () {
        this.addFileToVFS('OpenSans-Bold-normal.ttf', font);
        this.addFont('OpenSans-Bold-normal.ttf', 'OpenSans-Bold', 'normal');
        };
        jsPDF.API.events.push(['addFonts', callAddFont])
    
    const doc = new jsPDF('portrait', 'mm', 'a3')
    doc.setFont('OpenSans-Bold')

    let y_delta = 8
    let line_coord = 10
    doc.text(`Название проекта: ${traceProperties.projectName}`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Тип блока: ${traceProperties.block.title}`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Антенна ближней стороны: ${traceProperties.antennaHight.title}`,10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Антенна дальней стороны: ${traceProperties.antennaLow.title}`,10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Расстояние: ${traceProperties.range}`,10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Климатическая зона: ${traceProperties.climaticzone}`,10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Координаты ближней стороны: ${traceProperties.coords.high.lat} ${traceProperties.coords.high.lon}`,10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Координаты дальный стороны: ${traceProperties.coords.low.lat} ${traceProperties.coords.low.lon}`,10, line_coord)
    
    line_coord = line_coord + 30

    // Шапка
    doc.text(`Доступность (%)`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Полное затухание (дБ)`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Радио бюджет (дБ)`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Модуляция`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Скорость передачи (Мбит/c)`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`Чувствительность приёмника (дБм)`, 10, line_coord)
    line_coord = line_coord + y_delta
    doc.text(`RSSI (дБм)`, 10, line_coord)

    line_coord = line_coord - 6*y_delta
    let inline_coord = 130
    let delta_inline_coord = 35

    console.log(attTable)

    for (let line in attTable){
        doc.text(`${attTable[line].probability}`, inline_coord, line_coord)
        line_coord = line_coord + y_delta
        doc.text(`${attTable[line].att.toFixed(3)}`, inline_coord, line_coord)
        line_coord = line_coord + y_delta
        doc.text(`${attTable[line].budget.toFixed(3)}`, inline_coord, line_coord)
        line_coord = line_coord + y_delta
        doc.text(`${attTable[line].modulation}`, inline_coord, line_coord)
        line_coord = line_coord + y_delta
        doc.text(`${attTable[line].speed}`, inline_coord, line_coord)
        line_coord = line_coord + y_delta
        doc.text(`${attTable[line].prx}`, inline_coord, line_coord)
        line_coord = line_coord + y_delta
        doc.text(`${attTable[line].RSSI.toFixed(3)}`, inline_coord, line_coord)
        line_coord = line_coord - 6*y_delta
        inline_coord = inline_coord + delta_inline_coord

    }


    doc.save(`${traceProperties.projectName}.pdf`)
}



export default createPDF;