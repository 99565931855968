import SwitchBtn from "../UI/Buttons/SwitchBtn/SwitchBtn"
import classes from "./SwitchPanel.module.css"

function SwitchPanel({activePanel, setActivePanel}){
    /*activePanel
        0 - расчёт параметров
        1 - калькулятор
    */
    return(
        <div className={classes.wrapper}>
            <SwitchBtn  isActive = {activePanel}
                        onClick = {
                            () => setActivePanel(!activePanel)
                            }>Расчёт параметров</SwitchBtn>
            <SwitchBtn  isActive = {!activePanel}
                        onClick = {
                            () => setActivePanel(!activePanel)
                            }>Расчёт стороннего оборудования</SwitchBtn>
        </div>
    )
}

export default SwitchPanel;